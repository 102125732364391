@import "/home/ghrunner/actions-runner/_work/childrens-app/childrens-app/apps/childrens-app/src/styles/shared/shared.scss";
.ch-footer {
	--local-padding-y-large:                           #{ch-d("space_12x")};
	--local-padding-y-medium:                          #{ch-d("space_8x")};
	--local-padding-y-small:                           1.5rem;
  
	--local-banner-footer-color:                       #{ch-c("always_light_on_surface_strong")};
	--local-banner-footer-bg-color:                    #{ch-c("legacy-brand-purple")}; 
	--local-banner-footer-button-color:                #{ch-c("always_light_on_surface_strong")};
	--local-banner-footer-button-bg-color:             #{ch-hsla("purple_hc_20")};
	--local-banner-footer-button-bg-color-hover:       #{ch-hsla("purple_hc_10")};
  
	--local-main-footer-bg-color:                      #{ch-hsla("magenta_hc_40")};
	--local-main-footer-color-strong:                  #{ch-c("always_light_on_surface_strong")};
	--local-main-footer-color-medium:                  #{ch-c("always_light_on_surface_strong")};
	--local-main-footer-color-weak:                    #{ch-c("always_light_on_surface_weak")};
	--local-main-footer-color-variant:                 #{ch-c("always_light_on_surface_variant")};
	--local-main-footer-divider-color:                 #{ch-c("always_light_divider_alpha")};
	--local-main-footer-icon-button-color:             #{ch-c("always_light_on_surface_strong")};
	--local-main-footer-icon-button-bg-color:          #{ch-hsla("magenta_hc_40")};
	--local-main-footer-icon-button-bg-color-hover:    #{ch-hsla("magenta_hc_50")};
  
	--local-secondary-footer-color:                    #{ch-c("always_light_on_surface_strong")};
	--local-secondary-footer-bg-color:                 #{ch-hsla("purple_hc_20")};
	--local-secondary-footer-button-color:             #{ch-c("always_light_on_surface_strong")};
	--local-secondary-footer-button-bg-color:          #{ch-hsla("purple_hc_20")};
	--local-secondary-footer-button-bg-color-hover:    #{ch-hsla("purple_hc_10")};
  
	--ch-divider-border-color:                         var(--ch-c-always-light-divider-alpha);
	--ch-lh-body: 1.55;

	position: 			relative;
	margin-block-start: auto;
	color:              var(--local-main-footer-color-strong);
	background-color:	$brand-legacy-purple;
	line-height:		14px;

	.ch-container, :where(.ch-txt.is-label),  :where(.ch-txt.is-caps),  :where(.ch-txt.is-body) {
	  color:                 var(--local-main-footer-color-strong);
	  font-family: inherit;
	  margin-block: 0;

	}
  
	.ch-button {
	   --ch-button-min-height:                      2.5rem;
	   --ch-button-padding-x:                       #{ch-d("space_4x")};
	   --ch-button-bg-color:                        var(--local-banner-footer-button-bg-color);
	   --ch-button-color-hover:                     #{ch-c("always_light_on_surface_strong")};
	   --ch-button-bg-color-hover:                  var(--local-banner-footer-button-bg-color-hover);
	   --ch-button-text-align:                      left;

	 	background-color: var(--ch-button-bg-color, var(--ch-c-primary-accent));
     	display: inline-flex;
 		max-height: var(--ch-button-max-height, none);
     	padding-inline: var(--ch-button-padding-x-start, var(--ch-button-padding-x, 1.75em)) var(--ch-button-padding-x-end, var(--ch-button-padding-x, 1.75em));
		 padding-block: var(--ch-button-padding-y-start, var(--ch-button-padding-y, 0.5em)) var(--ch-button-padding-y-end, var(--ch-button-padding-y, 0.5em));
		font-size: var(--ch-button-font-size, var(--ch-fs--1)) !important;
		border-radius: 9999rem;
  
	  @include ch-break-hover() {
		&:hover {
		  text-decoration: none;
		}
	  }

	  :where(.ch-button-content) {
		display: flex;
		gap: var(--ch-button-content-gap, var(--ch-d-space-2x));
	  }

	  :where(.ch-button-content) :where(.ch-button-label) {
		position: relative;
	}
	
		:where(.ch-button-content) > :where(b, span) {
			margin-inline-end: calc(var(--ch-button-letter-spacing, var(--ch-ls-label))* -1);
			text-decoration-thickness: inherit;
		}
	}

	:where(.ch-icon-button) {
		isolation: isolate;
		display: inline-flex;
		flex-flow: row nowrap;
		justify-content: var(--ch-icon-button-justify-content, center);
		align-items: var(--ch-icon-button-align-items, center);
		position: relative;
		z-index: var(--ch-icon-button-z-index, auto);
		flex-grow: 0;
		flex-shrink: 0;
		flex-basis: auto;
		width: var(--ch-icon-button-width, 2em);
		height: var(--ch-icon-button-height, 2em);
		margin-inline: var(--ch-icon-button-margin-x-start, var(--ch-icon-button-margin-x, 0px)) var(--ch-icon-button-margin-x-end, var(--ch-icon-button-margin-x, 0px));
		margin-block: var(--ch-icon-button-margin-y-start, var(--ch-icon-button-margin-y, 0px)) var(--ch-icon-button-margin-y-end, var(--ch-icon-button-margin-y, 0px));
		border-radius: var(--ch-icon-button-border-radius, var(--ch-d-radius-rounded));
		font-size: var(--ch-icon-button-font-size, var(--ch-d-icon-size-scale-0)) !important;
		filter: var(--ch-icon-button-filter, none);
		backface-visibility: hidden;
		transition-duration: var(--ch-icon-button-transition-duration, var(--ch-dur-0));
		transition-property: var(--ch-icon-button-transition-property, color, background-color, box-shadow, opacity, transform, filter);
		transition-timing-function: var(--ch-icon-button-transition-timing-function, var(--ch-ease-std));
		cursor: var(--ch-icon-button-cursor, pointer);
		pointer-events: var(--ch-icon-button-pointer-events, auto);
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

	  background: var(--local-banner-footer-button-bg-color, transparent);
  
	  @include ch-break-hover() {
		&:hover {
		  background: var(--local-banner-footer-button-bg-color-hover, transparent);
		}
	  }
	}
	.is-banner a, .is-main-secondary a, .ch-footer-grid a {
	  @include ch-break-hover() {
		&:hover {
		  text-decoration: underline !important;
		}
	  }
	}
	.is-main-secondary a, .ch-footer-grid a {
	  color: var(--local-main-footer-color-variant);
  
	  @include ch-break-hover() {
		&:hover {
		  color: var(--local-main-footer-color-strong);
		}
	  }
	}
	.ch-button-label address.adr {
	  display: flex;
	  flex-direction: column;
	} 


  }
  
  // Section: Banner
  // =============================================================================
  
  .ch-footer-section.is-banner {
	background: var(--local-banner-footer-bg-color);
	border-bottom: 1px solid var(--ch-c-always-light-divider-alpha);
  
	a.has-caret::after {
	  content: url('data:image/svg+xml,<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6238 8.07327C11.8587 8.30829 11.8587 8.68832 11.6238 8.92084L6.82581 13.7238C6.59091 13.9588 6.21107 13.9588 5.97866 13.7238C5.74626 13.4887 5.74376 13.1087 5.97866 12.8762L10.3518 8.5008L5.97616 4.12292C5.74126 3.8879 5.74126 3.50787 5.97616 3.27535C6.21107 3.04283 6.59091 3.04033 6.82331 3.27535L11.6238 8.07327Z" fill="white" fill-opacity="0.85"/></svg>');
	  display: inline-block;
	  vertical-align: text-top; 
	}
  
	@include ch-break-up("md") {
	  padding-block: 0px;
	}
  }
  
  .ch-footer-container.is-banner {
	--ch-icon-font-size:    1em;
	--ch-icon-color:        var(--local-banner-footer-color);
	--ch-txt-color:         var(--local-banner-footer-color);
	--ch-divider-length:    24px;
	--ch-divider-align-self:  center;
  
	display: flex;
	gap: ch-d("space_3x");
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-block: 24px;
  
	@include ch-break-down("md") {
	  flex-flow: column nowrap;
  
	  span.ch-footer-social-links {
		padding-block-start:  ch-d("space_3x");
	  }
	}
  
  }
  
  .ch-footer-subscription-copy {
	--ch-icon-stroke-width:            1.5;
	--ch-baseline-icon-extra-width:    0px;
  
	@include ch-break-down("sm") {
	  display: flex;
	  gap: ch-d("space_4x");
	  flex-flow: column nowrap;
	  justify-content: flex-start;
	  align-items: center;
	  max-width: 24em;
	}
  
	strong {
	  flex-shrink: 0;
	  display: inline-flex;
	  gap: 0.5em;
	  flex-flow: row nowrap;
	  justify-content: space-between;
	  align-items: center;
	  position: relative;
	  white-space: nowrap;
  
	  @include ch-break-down("sm") {
		font-size: ch-fs("body_-1");
	  }
  
	  @include ch-break-up("lg") {
		margin-inline-end: 0.25em;
	  }
	}
  }
  
  
  // Section: Main
  // =============================================================================
  
  .ch-footer-section.is-main {
	--ch-icon-font-size:    1em;
	--ch-icon-color:        var(--local-banner-footer-color);
	--ch-txt-color:         var(--local-banner-footer-color);
  
	--ch-expand-hitbox-x: 0px;
	--ch-expand-hitbox-y: 6px;
  
	background: var(--local-banner-footer-bg-color);
	display: flex;
	gap: var(--local-padding-y-small);
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	text-align: center;
	padding-block-start: var(--local-padding-y-large);
  
	@include ch-break-down("md") {
	  padding-block-start: var(--local-padding-y-medium);
	  gap: var(--local-padding-y-medium);
	}
  
	.ch-footer-grid {
	  column-width: 300px; 
	  column-gap: 1rem;
	  width: 100%;
	  text-align: left;
  
	  ul {
		list-style-type: none;
		break-inside: avoid; 
		font-weight: ch-fw("label_bold");
		font-size: var(--ch-fs-label--1);
		padding-block-end: var(--local-padding-y-large);
		display: flex;
		gap: ch-d("space_3x");
		flex-direction: column;
		 
	   li {
		  a {
			font-weight: ch-fw("label_normal");
			position: relative;
			display: block;
		  }
  
		  a::after {
			content: url('data:image/svg+xml,<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.6238 8.07327C11.8587 8.30829 11.8587 8.68832 11.6238 8.92084L6.82581 13.7238C6.59091 13.9588 6.21107 13.9588 5.97866 13.7238C5.74626 13.4887 5.74376 13.1087 5.97866 12.8762L10.3518 8.5008L5.97616 4.12292C5.74126 3.8879 5.74126 3.50787 5.97616 3.27535C6.21107 3.04283 6.59091 3.04033 6.82331 3.27535L11.6238 8.07327Z" fill="white" fill-opacity="0.85"/></svg>');
			margin-left: 0.5rem; 
			display: inline-block;
			vertical-align: sub; 
		  }
  
		  a:before {
			content: "";
			position: absolute;
			inset-inline-start: calc(var(--ch-expand-hitbox-x-start, var(--ch-expand-hitbox-x, var(--ch-expand-hitbox, var(--ch-d-space-4x))))* -1);
			inset-inline-end: calc(var(--ch-expand-hitbox-x-end, var(--ch-expand-hitbox-x, var(--ch-expand-hitbox, var(--ch-d-space-4x))))* -1);
			inset-block-start: calc(var(--ch-expand-hitbox-y-start, var(--ch-expand-hitbox-y, var(--ch-expand-hitbox, var(--ch-d-space-4x))))* -1);
			inset-block-end: calc(var(--ch-expand-hitbox-y-end, var(--ch-expand-hitbox-y, var(--ch-expand-hitbox, var(--ch-d-space-4x))))* -1);
		
		  }
		}
	  }
	}
  
	/* For tablets: 2 columns */
	@media (max-width: 1023px) {
	  .ch-footer-grid {
		  column-count: 2;
	  }
	}
  
	/* For mobile: 1 column */
	@media (max-width: 767px) {
	  .ch-footer-grid {
		  column-count: 1;
	  }
	}
  }
  .ch-footer-section.is-secondary {
	display: flex;
	background: var(--local-secondary-footer-bg-color);
	gap: var(--local-padding-y-small);
	flex-flow: column nowrap;
	padding-block: var(--local-padding-y-medium);
  
	.ch-footer-container.is-main-secondary {
	  padding-block: 0;
	}
	.ch-footer-container.is-main-primary {
	  gap: 0;
	  .ch-txt.is-body {
		color: var(--local-main-footer-color-variant);
	  }
	}
  }
  
  // Primary
  // -------
  
  .ch-footer-container.is-main-primary {
	display: flex;
	gap: var(--local-padding-y-small);
	flex-flow: row wrap;
	justify-content: center;
	align-items: flex-start;
	text-align: center;
  
	@include ch-break-up("sm") {
	  justify-content: space-between;
	  text-align: inherit;
	}
  }
  
  .ch-footer-intro {
	--ch-brand-width:        192.25px;
	--ch-brand-max-width:    var(--ch-brand-width);
	--ch-txt-color:          var(--local-main-footer-color-weak);
  
	display: flex;
	gap: ch-d("space_2x");
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: stretch;
	width: max-content;
  }
  
  .ch-footer-hero-nav {
	--ch-auto-grid-gap-x:           #{ch-d("space_4x")};
	--ch-auto-grid-gap-y:           #{ch-d("space_1x")};
	--ch-auto-grid-template-min:    999rem;
	--ch-auto-grid-width:           100%;
  
  
  
	--ch-txt-font-size:             #{ch-fs("4")};
	--ch-txt-color:                 var(--local-main-footer-color-strong);
	--ch-txt-color-hover:           var(--local-main-footer-color-strong);
  
	@include ch-break-up("md") {
	  --ch-auto-grid-template-min:    20rem;
	}
  
	@include ch-break-up("lg") {
	  --ch-auto-grid-gap-x:            #{ch-d("space_12x")};
	  --ch-auto-grid-repeat-count:     2;
	  --ch-auto-grid-repeat-tracks:    max-content;
	  --ch-auto-grid-width:            auto;
	}
  
	@include ch-break-up("xl") {
	  --ch-txt-font-size:    #{ch-fs("4")};
	}
  }
  
  
  // Divider
  // -------
  
  .ch-footer-container.is-main-divider {
	--ch-divider-border-color:    var(--local-main-footer-divider-color);
  }
  .ch-divider-line {
	border-top-width: 1px;
    border-color: var(--ch-divider-border-color, var(--ch-c-neutral-divider-alpha));
  }
  
  // Secondary
  // ---------
  
  .ch-footer-container.is-main-secondary {
	display: flex;
	gap: ch-d("space_3x") ch-d("space_4x");
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	padding-block-start: var(--local-padding-y-large);
	padding-block-end: var(--local-padding-y-small);
	flex-direction: row;
	flex-wrap: wrap;
  
	@include ch-break-up("md") {
	  flex-flow: row wrap;
	  justify-content: flex-start;
	  text-align: inherit;
	}
  }
  
  .ch-footer-social-links {
    display: flex;
	gap: #{ch-d("space_2x")};
	--ch-auto-flex-justify-content:                   center;
	--ch-auto-flex-max-width:                         none;
	--ch-auto-flex-align-items:                       center;
  
	--ch-icon-button-font-size:                       #{ch-d("icon_size_scale_-1")};
  
	--ch-icon-button-color:                           var(--local-main-footer-icon-button-color);
	--ch-icon-button-bg:                              var(--local-main-footer-icon-button-bg-color);
	--ch-icon-button-box-shadow:                      none;
	--ch-icon-button-state-layer-bg-color:            transparent;
	--ch-icon-button-state-layer-box-shadow:          none;
  
	--ch-icon-button-color-hover:                     var(--local-main-footer-icon-button-color);
	--ch-icon-button-bg-hover:                        var(--local-main-footer-icon-button-bg-color-hover);
	--ch-icon-button-box-shadow-hover:                none;
	--ch-icon-button-state-layer-bg-color-hover:      transparent;
	--ch-icon-button-state-layer-box-shadow-hover:    none;
  
	@include ch-break-up("md") {
	  --ch-auto-flex-max-width:    none;
	}

	:where(.ch-icon svg) {
		fill: white;
		stroke: none;
		paint-order: fill;
	}

	a.ch-txt {
		display: flex;
		align-items: center;
	}
  }
  
  .ch-footer-legal-links {
	--ch-auto-flex-max-width:          24rem;
	--ch-auto-flex-justify-content:    center;
	--ch-auto-flex-gap-x:              #{ch-d("space_4x")};
	--ch-auto-flex-gap-y:              #{ch-d("space_2x")};
  
	--ch-txt-color:                    var(--local-main-footer-color-weak);
	--ch-txt-color-hover:              var(--local-main-footer-color-strong);
  
	@include ch-break-up("md") {
	  --ch-auto-flex-justify-content:    flex-start;
	}
  }